<template>
  <custom-dialog
      :visible.sync="visible"
      :title="title"
      width="50%"
      @close="colseDialog"
      @submit="submit"
      :isShow="isEdit">
    <el-form
        :model="editForm"
        label-width="120px"
        class="edit-form"
        :rules="rules"
        ref="editAcceptanceForm">
      <el-row :gutter="20" class="edit-form-row">
        <el-col :span="12">
          <el-form-item label="所属酒店：" prop="hotelId">
            <el-select v-model="editForm.hotelId" size="small"
                       :disabled="isEdit"
                       class="block-select" placeholder="请选择所属酒店"
                       @change="hotelChange">
              <el-option
                  v-for="item in hotelList"
                  :key="item.hotelId"
                  :label="item.hotelName"
                  :value="item.hotelId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始时间：" prop="startTime">
            <div>{{editForm.startTime}}</div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="会场：" prop="meetId">
            <el-select
                class="block-select"
                v-model="editForm.placeId"
                size="small"
                placeholder="请选择会场"
                @change="placeChange"
                :disabled="isEdit">
              <el-option
                  v-for="item in placeList"
                  :key="item.placeId"
                  :label="item.placeName"
                  :value="item.placeId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间：" prop="endTime">
            <div>{{editForm.endTime}}</div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="会议名称：" prop="meetId">
            <el-select
                class="block-select"
                v-model="editForm.meetId"
                size="small"
                placeholder="请选择会议名称"
                :disabled="isEdit">
              <el-option
                  v-for="item in meetingList"
                  :key="item.meetId"
                  :label="item.meetName"
                  :value="item.meetId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主讲人：" prop="meetHost">
            <div>{{editForm.meetHost}}</div>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="line"></div>
        </el-col>
        <el-col :span="24">
          <el-form-item label="会议纪要" prop="annexList">
            <el-upload
                class="file-uploader"
                :disabled="isEdit"
                :action="`${uploadUrl}File/upload`"
                :data="{type:1}"
                :show-file-list="false"
                :on-success="uploadSuccess">
              <div class="upload-main">
                  <span class="upload-btn">
                    <i class="el-icon-upload"></i>上传附件
                  </span>
                仅支持上传图片、pdf格式
              </div>
              <div class="file-url-main">
                <div v-for="(url,index) in editForm.annexList" class="file-url-item" :key="index">
                  <div class="file-url">{{url.annexUrl.match('--')? url.annexUrl.split('--')[1]: url.annexUrl}}</div>
                  <span class="file-url-btn el-icon-close" circle @click.stop="delImg(index)"></span>
                </div>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注：" prop="minuNote">
            <el-input
                size="small"
                type="textarea"
                :rows="2"
                v-model="editForm.minuNote"
                placeholder="请输入备注"
                :disabled="isEdit"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import config from "../../../api/config";
import meeting from "../../../api/modules/meeting";
import accommodation from "../../../api/modules/accommodation";

export default {
  name: "managementEdit",
  components: { CustomDialog},
  props: ["visible", "title", "item", "isEdit"],
  data() {
    return {
      editForm: {
        annexList: [],
      },
      rules: {
        annexList: [
          {required: true, message: "请选择会议纪要", trigger: "blur"},
        ],
        minuNote: [
          {required: true, message: "请输入备注", trigger: "blur"},
        ],
      },
      uploadUrl: config.uploadUrl,
      projList: [],
      hotelList:[],
      placeList:[],
      meetingList:[]
    };
  },
  watch:{
    item(newVal,oldVal){
      // console.log(newVal);
      this.editForm = {...newVal}
      // this.editForm.annexUrl =  {...newVal.annexUrl}
      if( newVal.hotelId || newVal.hotelId ==0){
        this.getPlaceList(newVal.hotelId)
        this.getMeetingList(newVal.placeId)
      }
    },
    deep:true
  },
  mounted() {
    this.getHotelInfo()
  },
  methods: {
    getHotelInfo() {
      accommodation.getHotelList().then(res => {
        this.hotelList = res.data
      })
    },
    hotelChange(val) {
      // 通过酒店信息查询 会场、会议信息
      this.getPlaceList(val)
    },
    getPlaceList(hotelId){
      meeting.getMeetPlaceByHotelId({hotelId:hotelId}).then(res =>{
        this.placeList = res.data
      })
    },
    placeChange(val){
      this.getMeetingList(val)
    },
    getMeetingList(placeId){
      meeting.getMeetInfoByPlaceId({placeId:placeId}).then(res =>{
        this.meetingList = res.data
      })
    },
    submit() {
      this.$refs.editAcceptanceForm.validate((valid) => {
        if (valid) {
          console.log(this.editForm);
          let params = {
            annexList: this.editForm.annexList,
            meetId: this.editForm.meetId,
            minuNote: this.editForm.minuNote
          }
          meeting.addMeetingMinutesInfo(params).then((res) => {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$emit("done");
            this.resetForm();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    colseDialog() {
      this.$emit("close");
    },
    resetForm() {
      if (this.$refs.editAcceptanceForm) {
        this.$refs.editAcceptanceForm.clearValidate();
        this.$refs.editAcceptanceForm.resetFields();
      }
    },
    uploadSuccess(val) {
      // 附件
      if (val.code == 200) {
        this.editForm.annexList.push({
          annexUrl: val.data,
          annexType: 3
        })
      }
    },
    delImg(index) {
      this.editForm.annexList.splice(index, 1)
      this.$forceUpdate()
    },
  },
};
</script>

<style scoped lang="less">
.edit-form {
  padding: 20px;
}

.line {
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  margin-bottom: 15px;
}

.blankCol {
  height: 40px;
  margin-bottom: 20px;
}

.footer-save {
  position: absolute;
  bottom: 18px;
  right: 250px;
}
</style>
  